exports.components = {
  "component---src-components-layout-historia-template-tsx": () => import("./../../../src/components/layout/HistoriaTemplate.tsx" /* webpackChunkName: "component---src-components-layout-historia-template-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acompanamiento-apoyar-sobrevivientes-tsx": () => import("./../../../src/pages/acompanamiento/apoyar_sobrevivientes.tsx" /* webpackChunkName: "component---src-pages-acompanamiento-apoyar-sobrevivientes-tsx" */),
  "component---src-pages-acompanamiento-que-puedo-hacer-tsx": () => import("./../../../src/pages/acompanamiento/que_puedo_hacer.tsx" /* webpackChunkName: "component---src-pages-acompanamiento-que-puedo-hacer-tsx" */),
  "component---src-pages-acompanamiento-tsx": () => import("./../../../src/pages/acompanamiento.tsx" /* webpackChunkName: "component---src-pages-acompanamiento-tsx" */),
  "component---src-pages-autocuidado-compartiendo-nuestra-historia-tsx": () => import("./../../../src/pages/autocuidado/compartiendo_nuestra_historia.tsx" /* webpackChunkName: "component---src-pages-autocuidado-compartiendo-nuestra-historia-tsx" */),
  "component---src-pages-autocuidado-creando-linea-tiempo-tsx": () => import("./../../../src/pages/autocuidado/creando_linea_tiempo.tsx" /* webpackChunkName: "component---src-pages-autocuidado-creando-linea-tiempo-tsx" */),
  "component---src-pages-autocuidado-cuidando-nuestro-cuerpo-tsx": () => import("./../../../src/pages/autocuidado/cuidando_nuestro_cuerpo.tsx" /* webpackChunkName: "component---src-pages-autocuidado-cuidando-nuestro-cuerpo-tsx" */),
  "component---src-pages-autocuidado-mi-pequeno-libro-tsx": () => import("./../../../src/pages/autocuidado/mi_pequeno_libro.tsx" /* webpackChunkName: "component---src-pages-autocuidado-mi-pequeno-libro-tsx" */),
  "component---src-pages-autocuidado-tsx": () => import("./../../../src/pages/autocuidado.tsx" /* webpackChunkName: "component---src-pages-autocuidado-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-directorio-tsx": () => import("./../../../src/pages/directorio.tsx" /* webpackChunkName: "component---src-pages-directorio-tsx" */),
  "component---src-pages-historia-tsx": () => import("./../../../src/pages/historia.tsx" /* webpackChunkName: "component---src-pages-historia-tsx" */),
  "component---src-pages-historias-tsx": () => import("./../../../src/pages/historias.tsx" /* webpackChunkName: "component---src-pages-historias-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nosotras-tsx": () => import("./../../../src/pages/nosotras.tsx" /* webpackChunkName: "component---src-pages-nosotras-tsx" */),
  "component---src-pages-sistema-de-justicia-tsx": () => import("./../../../src/pages/sistema_de_justicia.tsx" /* webpackChunkName: "component---src-pages-sistema-de-justicia-tsx" */),
  "component---src-pages-sistema-de-salud-tsx": () => import("./../../../src/pages/sistema_de_salud.tsx" /* webpackChunkName: "component---src-pages-sistema-de-salud-tsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-vivi-violencia-sexual-tsx": () => import("./../../../src/pages/vivi_violencia_sexual.tsx" /* webpackChunkName: "component---src-pages-vivi-violencia-sexual-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

